<template>
    <div class="select-component" :class="{'select-component--hasError': error.length, multiple, searchable}">
        <span v-if="showLabel" class="select-component__label">{{ placeholder }}</span>
        <v-select
            ref="select"
            :placeholder="showLabel ? '' : placeholder"
            :multiple="multiple"
            :value="valueAfterLoad"
            :reduce="option => option.id"
            :options="options"
            :searchable="searchable"
            :clearable="clearable"
            @close="close"
            class="select-component__select"
            :class="{
                'select-component__select--disabled' : disabled,
                'select-component__select--error' : error.length, 
                'select-component__select--multiple': multiple
            }"
            @input="e => $emit('input', e, filterId)"
        >
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                    <span class="material-icons-round">keyboard_arrow_down</span>
                </span>
            </template>
            <span slot="no-options">{{ $t('components.form.no-options') }}</span>
        </v-select>

        <input type="hidden" :name="name" :value="valueAfterLoad"/>
        <span v-if="error.length" class="select-component__error">{{ error[0] }}</span>
    </div>
</template>

<script>
export default {
    name: "SelectComponents",
    props: {
        value: {
            required: true
        },
        name: {
            type: String,
            required: true
        },
        options: {
            required: true,
            type: Array
        },
        multiple: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: true
        },
        showLabel:{
          type: Boolean,
          default: false
        },
        placeholder: {
            required: false,
            type: String
        },
        clearable: {
            required: false,
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filterId: {
            type: Number,
            required: false,
            default: null,
        }
    },
    mounted() {
        this.$refs.select.open = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
    computed: {
        isLoaded() {
            return this.options && this.options.length >= 1
        },
        valueAfterLoad() {
            if(!this.isLoaded) {
                return;
            }

            if(Array.isArray(this.value)) {
                return this.value.map(el => +el);
            }

            if(this.options.find(x => +x.id === +this.value)) {
                return +this.value;
            }
        }
    }
}
</script>


<style lang="scss">

    .select-component {
        position: relative;

        &__label {
            display: block;
            position: relative;
            margin-bottom: 10px;
            display: block;
            color: #4e515f;
            font-size: 20px;
        }

        &__select {
            min-width: 220px;
            max-width: 220px;

            .vs__dropdown-toggle {
                border: 1px solid #e8ecee;
                border-radius: 0;
                font-size: 16px;
                font-family: $ff-primary;
                background-color: white;
                padding: 7px 7px;
                width: 100%;
                box-sizing: border-box;
            }
            

            &--disabled {
                background-color: $lightgrey;
            }
        }

        &__error {
            border-color: red
        }

        .vs__actions{
            padding: 0;

            span{
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $blue;
            }
        }

        .vs__selected {
            top: -2px;
            position: relative;

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .vs__search {
            width: 100%;
            position: absolute;
        }

        .vs__dropdown-option {
            white-space: initial;
        }
    }

</style>
