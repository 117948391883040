<template>
    <div class="input-component">
        <label class="input-component__label">
            <span v-if="showLabel" class="input-component__spanCaption">
                {{ placeholder }}
                <span v-if="required">{{ $t('components.form.required') }}</span>
            </span>
            <input
                :type="type"
                :value="value"
                :name="name"
                :required="required"
                :placeholder="!showLabel && placeholder ? placeholder : ''"
                @input="$emit('input', $event.target.value)"
                class="input-component__input"
                :class="{ 
                    'input-component__input--error': error.length
                }"
                :disabled="disabled"
            />
        </label>

        <span v-if="icon" class="material-icons-round input-component__icon">{{ icon }}</span>
        <span
            v-if="activeIcon" 
            class="material-icons-round input-component__icon input-component__icon--active" 
            @click="$emit('icon-click', false)"
        >
            {{ activeIcon }}
        </span>
        
        <span v-if="error" class="input-component__error">{{ error[0] }}</span>
    </div>
</template>
<script>
export default {
    name: 'InputComponent',
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            required: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        placeholder: {
            type: String,
            required: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            default: false
        },
        required: {
            required: false,
            default: false
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        activeIcon: {
            type: String,
            required: false,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>

.input-component {
    position: relative;

    &__label {
        display: block;
        position: relative;
    }

    &__spanCaption {
        margin-bottom: 10px;
        display: block;
        color: #4e515f;
        font-size: 20px;
    }

    &__error {
        position: absolute;
        bottom: -18px;
        left: 0;
        font-size: 12px;
        text-align: left;
        width: 100%;
        color: red;
    }

    &__icon {
        position: absolute;
        color: $blue;
        font-size: 24px;
        right: 0;
        bottom: 0;
        padding: 10px;
        padding-right: 14px;

        &--active {
            cursor: pointer;

            &:hover {
                color: $yellow;
            }
        }
    }

    &__input {
        border: 1px solid #e8ecee;
        border-radius: 0;
        font-size: 16px;
        font-family: $ff-primary;
        background-color: white;
        padding: 10px 15px;
        width: 100%;
        box-sizing: border-box;

        &--error {
            border-color: red
        }

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $grey
        }

        &[disabled] {
            background-color: $lightgrey;
        }
    }
}

/* Hide arrows for input type number
    Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

</style>

