<template>
    <div class="feedback">
        <BannerInnerPage />

        <div class="container feedback__breads">
            <Breads :links="breadLinks" />
        </div>

        <div class="feedback__content">
            <div class="container">
                <SectionTitle :title="$t('pages.Feedback.title')" :hasButton="false"/>
                <div class="feedback__form">
                    <div class="feedback__formItem">
                        <InputComponent
                            :show-label="true" 
                            :placeholder="$t('pages.Feedback.form.name')" 
                            v-model="name"
                            name="name"
                            :error="errors.name"
                            type="text"
                            :required="true"
                        />
                    </div>
                    <div class="feedback__formItem">
                        <InputComponent
                            :show-label="true" 
                            :placeholder="$t('pages.Feedback.form.email')" 
                            v-model="email"
                            name="email"
                            :error="errors.email"
                            type="email"
                            :required="true"
                        />
                    </div>
                    <div class="feedback__formItem">
                        <InputComponent
                            :show-label="true" 
                            :placeholder="$t('pages.Feedback.form.theme')" 
                            v-model="theme"
                            type="text"
                            name="theme"
                            :error="errors.subject"
                        />
                    </div>
                    <div class="feedback__formItem">
                        <TextareaComponent
                            :show-label="true" 
                            :placeholder="$t('pages.Feedback.form.message')" 
                            v-model="message"
                            type="text"
                            name="theme"
                            :max="1000"
                            :error="errors.question"
                        />
                    </div>

                    <ButtonComponent class="feedback__send" type="button" @click="goRecaptcha" :preloader="preloader" :disabled="preloader" :caption="$t('pages.Feedback.form.send')"/>
                </div>
            </div>
        </div>

        <vue-recaptcha
            size="invisible"
            ref="recaptcha"
            :sitekey="recaptchaKey"
            :loadRecaptchaScript="true"
            @verify="submit"
            @expired="onCaptchaExpired"
        />

        <Footer />
    </div>
</template>

<script>

import Footer from "@/components/Footer"
import BannerInnerPage from "@/components/BannerInnerPage"
import SectionTitle from "@/components/SectionTitle"
import InputComponent from "@/components/form/InputComponent"
import TextareaComponent from "@/components/form/TextareaComponent"
import SelectComponent from "@/components/form/SelectComponent"

import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        Footer,
        BannerInnerPage,
        SectionTitle,
        InputComponent,
        TextareaComponent,
        SelectComponent,
        VueRecaptcha
    },

    data() {
        return {
            name: '',
            email: '',
            theme: '',
            message: '',
            breadLinks: [
                {
                    route: 'Feedback',
                    text: this.$i18n.t('pages.Feedback.title')
                },
            ],
            errors: {
                name: [],
                email: [],
                subject: [],
                question: []
            },
            preloader: false,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY
        }
    },

    methods: {
        submit(tokenByRecaptcha) {
            this.preloader = true
            this.api.feedback.index({
                name: this.name,
                email: this.email,
                subject: this.theme,
                question: this.message,
                "g-recaptcha-response": tokenByRecaptcha
            }).then(r => {
                this.toast.info(this.$i18n.t('components.Toast.infoTitle'), this.$i18n.t('pages.Feedback.successSubmit'))
                this.name = '';
                this.email = '';
                this.theme = '';
                this.message = '';

                this.clearErrors()
                this.onCaptchaExpired();
            }).catch(e => {
                this.errors = e.response?.data?.errors
            }).finally(() => {
                this.preloader = false
            })
        },

        clearErrors() {
            this.errors = {
                name: [],
                email: [],
                subject: [],
                question: [],
            }
        },

        goRecaptcha() {
            this.$refs.recaptcha.reset()
            this.$refs.recaptcha.execute()
        },

        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        }
    }
}
</script>

<style lang="scss" scoped>
    .feedback {

        &__breads {
            padding-top: 30px;
        }

        &__content {
            padding: 70px 0;
        }

        &__form {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -10px;
            margin-top: 40px;
        }

        &__formItem {
            flex-basis: calc(33.33% - 20px);
            margin: 10px;

            &:last-of-type {
                flex-basis: 100%;
            }

            @media screen and (max-width: $lg) {
                flex-basis: 100%
            }
        }

        &__send {
            width: max-content;
            margin: 0 auto;
            margin-top: 20px;
        }
    }
</style>