<template>
    <div class="textarea-component">
        <label class="textarea-component__label">
            <span v-if="showLabel" class="textarea-component__spanCaption">
                {{ placeholder }}
                <span v-if="required">{{ $t('components.form.required') }}</span>
            </span>
            <textarea
                :value="value"
                :name="name"
                :required="required"
                :placeholder="!showLabel && placeholder ? placeholder : ''"
                @input="$emit('input', $event.target.value)"
                class="textarea-component__textarea"
                :class="{ 
                    'textarea-component__textarea--error': error.length
                }"
                :disabled="disabled"
                :style="{ height: `${height}px` }"
            />
            <p v-if="max" class="textarea-component__max" :class="{ 'textarea-component__max--error' : value.length > max }">{{ value.length }} / {{ max }}</p>
        </label>
        <span v-if="error" class="textarea-component__error">{{ error[0] }}</span>
    </div>
</template>
<script>
export default {
    name: 'TextareaComponent',
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            required: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            required: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            default: false
        },
        required: {
            required: false,
            default: false
        },
        height: {
            required: false,
            default: 200
        },
        max: {
            required: false,
            default: null,
            type: Number
        }
    },
}
</script>

<style lang="scss" scoped>

.textarea-component {
    position: relative;

    &__label {
        display: block;
        position: relative;
    }

    &__spanCaption {
        margin-bottom: 10px;
        display: block;
        color: #4e515f;
        font-size: 20px;
    }

    &__error {
        position: absolute;
        bottom: -18px;
        left: 0;
        font-size: 12px;
        text-align: left;
        width: 100%;
        color: red;
    }

    &__textarea {
        border: 1px solid #e8ecee;
        border-radius: 0;
        font-size: 16px;
        font-family: $ff-primary;
        background-color: white;
        padding: 10px 15px;
        width: 100%;
        box-sizing: border-box;
        resize: none;

        &--error {
            border-color: red
        }

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $grey
        }

        &[disabled] {
            background-color: $lightgrey;
        }
    }

    &__max {
        margin: 0;
        font-size: 14px;
        position: absolute;
        right: 0;

        &--error {
            color: red;
        }
    }
}

</style>

